<template>
  <div class="flex flex-col mb-10">
    <div style="margin-left: -10px; margin-top: 20px">
        <div class="md-layout" v-for="(v, index) in $v.education.$each.$iter" v-bind:key="index"  >
          
            <div class="md-layout-item md-size-100 mt-5" :class="{ 'form-group--error': v.$error }">
             
             <InputFieldComponent
                label="Educational Institue"
                :requiredLabel="true"
                class="w-full"
                placeholder=""
                v-model.trim="v.institue.$model"
                :message="!v.institue.required && v.institue.$dirty ? 'Field is required' : null"
            />
            </div>
            <div class="md-layout-item md-size-70 mt-5" :class="{ 'form-group--error': v.$error }">
             <InputFieldComponent
                label="Course"
                :requiredLabel="true"
                class="w-full"
                placeholder=""
                v-model.trim="v.course.$model"
                :message="!v.course.required && v.course.$dirty ? 'Field is required' : null"
            />
            </div>
        
            <div class="md-layout-item md-size-30 mt-12">
              <div  @click="education.push({institue: '', course: ''})" class="bg-victoria pointer center w-10 h-10 rounded">
                <md-icon class="text-2xl text-white">add</md-icon>
              </div>
              <div @click="education.pop()"  class="bg-danger pointer center w-10 h-10 rounded ml-1">
                <md-icon class="text-2xl text-white">remove</md-icon>
              </div>
            </div>
        <div>
        </div>
      </div>
    </div>
    <div class="flex justify-end mt-4">
      <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button>
      <Button
          :loading="loading"
          class="bg-victoria rounded text-white mr-0"
          label="Save & Next"
          type="button"
          @click="submit"
      >
      </Button>
    </div>
  </div>
</template>

<script>
import {
  Button,
  InputFieldComponent
} from "@/components";
import { required  } from "vuelidate/lib/validators";
export default {
  components: {
    Button,
    InputFieldComponent,

  },

  data() {
    return {
      loading: false,
      radio: false,
      education: [
        {
          institue: '',
          course:'',
        },
      ]
    }
  },

  methods: {


    submit() {

    
      this.$v.$touch();
      if(this.$v.$invalid) return;

      let formData = this.getLocal('job-application-basic-info');
      formData.educational_details = [
        ...this.education
      ];
      this.setLocal('job-application-basic-info', formData);
      this.$emit("menu", this.$route.name);
      this.$router.push({ name: 'employmentHistory' });
    },

    clearAll() {

      this.education = [
        {
          institue: '',
          course:'',
        },
      ]

      let formData = this.getLocal('job-application-basic-info');
      console.log(formData);
      formData.educational_details = [
        ...this.education
      ];

      this.$emit("menu", this.$route.name+'#false');
      this.setLocal('job-application-basic-info', formData);
    }
  },

  created() {
    
    let existData = this.getLocal('job-application-basic-info');
    if(existData && existData.educational_details) this.education = existData.educational_details;
   
  },
  validations: {
    education: {
      required,
      $each: {
        institue: {
          required,
        },
        course: {
          required,
        }
      }
    }
  }
}
</script>
<style scoped>
.nav-check{
  display: block!important;
}
</style>